<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="less">
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: PingFang SC;
  background: #ffffff;
}
</style>
