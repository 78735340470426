import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/equipment",
    component: () => import("../views/equipment.vue"),
    hidden: true,
  },
  {
    path: "/detection",
    component: () => import("../views/detection.vue"),
    hidden: true,
  },
  {
    path: "/system",
    component: () => import("../views/system.vue"),
    hidden: true,
  },
  {
    path: "/iris",
    component: () => import("../views/iris.vue"),
    hidden: true,
  },
  {
    path: "/about",
    component: () => import("../views/about.vue"),
    hidden: true,
  },
  {
    path: "/news",
    component: () => import("../views/news.vue"),
    hidden: true,
  },
];
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
