import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/index.scss"; // global css
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "@/utils/rem.js";
// 1.图片懒加载插件
import VueLazyload from "vue-lazyload";

Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  // 添加一些配置参数 可不填

  // 懒加载默认加载图片
  loading: require("../src/assets/loading.gif"),
  // 加载失败后加载的图片
  error: require("../src/assets/error.gif"),
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3, // 尝试加载次数
});

new Vue({
  router, // （缩写）相当于 router: router
  VueAwesomeSwiper,
  VueLazyload,
  render: (h) => h(App),
}).$mount("#app");
