// rem等比适配配置文件
// 基准大小
const baseSize = 16;
// 设置 rem 函数
function setRem() {
  // 当前页面屏幕分辨率相对于 1920宽的缩放比例，可根据自己需要修改
  const screenWidth = 1920;
  const scale = screenWidth / baseSize;
  const htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;

  const htmlDom = document.getElementsByTagName("html")[0];

  htmlDom.style.fontSize = htmlWidth / scale + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
